<template>
<div>
  <div class="container-fluid">
    <PageTitle />

    <template v-if="isList">
      <card-list 
        :filter.sync="filter"
        :dataListing.sync="data"
        :idKey="idKey"
        :fields="fields"

        :no-btn-mass="true"
        :no-multi-select="true"
      > 
        <template #bul_is_active="data">
          <LabelStatus :status="data.scope.item[statusKey]" />
        </template>
        <template #bul_role_rel="data">
          {{getModuleName(data.scope.value||[])}}
        </template>
        <template #aksi="data">
          <div v-if="!moduleRole('ViewOnly')" class="btn-toolbar">
            <b-dropdown no-flip>
              <template #button-content>
                <i class="icon-menu"></i>
              </template>
              <b-dropdown-item :to="{name:$route.name, params:{pageSlug:data.scope.item[idKey]}}">
                {{ !moduleRole('ViewOnly') ? 'Ubah Data' : 'Lihat Data' }}
              </b-dropdown-item>
              <b-dropdown-item v-if="!moduleRole('ViewOnly')" @click="doDelete(data.scope.index, data.scope.item)">Hapus</b-dropdown-item>
            </b-dropdown>
            <b-dropdown 
              v-if="!moduleRole('ViewOnly')"
              no-flip 
              variant="info" 
              class="ml10" toggle-class="absolute-button"
            >
              <template #button-content>
                <i class="ti-exchange-vertical" v-b-tooltip.hover 
              title="Ganti Status"></i>
              </template>
              <b-dropdown-item 
                :class="{markedDropdown:data.scope.item[statusKey]==1}"
                @click="doChangeStatus(data.scope.index, data.scope.item, 1)"
              >
                Active
              </b-dropdown-item>
              <b-dropdown-item 
                :class="{markedDropdown:data.scope.item[statusKey]==2}"
                @click="doChangeStatus(data.scope.index, data.scope.item, 2)"
              >
                Inactive
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <span v-else>-</span>
        </template>
      </card-list>
    </template>
    <div 
      v-else 
      class="card"
    >
      <div class="card-body">
        <h5 class="card-title mb-3">{{isAdd?'Tambah':'Ubah'}} {{pageTitle}}</h5>
        
        <ValidationObserver v-slot="{ handleSubmit }" ref="VForm">
          <form @submit.prevent="handleSubmit(doSubmitCRUD)">
            <b-row>
              <b-col md=8>
                <div class="form-group">
                  <label class="control-label">
                    Tipe Level
                  </label>
                  <VSelect 
                    v-model="row.bul_role_rel" 
                    placeholder="Select Module Role"
                    :options="mrRole" 
                    :reduce="v=>v.value" 
                  />
                  <VValidate 
                    name="Tipe Level" 
                    :value="((row.bul_role_rel||[]).length||null)" rules="required"
                  />
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col md=3>
                <div class="form-group">
                  <label class="control-label">
                    Kode Level 
                    <span class="text-danger mr5">*</span>
                  </label>
                  <input 
                    v-model="row.bul_code"
                    type="text" 
                    class="form-control" 
                    placeholder="e.g. ADMIN" 
                    :readonly="row.bul_create_date"
                  /> 
                  <VValidate 
                    name="Kode Level" 
                    v-model="row.bul_code" 
                    :rules="mrValidation.bul_code" 
                  />
                </div>
              </b-col>
              <b-col md=5>
                <div class="form-group">
                  <label class="control-label">
                    Nama Level 
                    <span class="text-danger mr5">*</span>
                  </label>
                  <input 
                    v-model="row.bul_level_name"
                    type="text" 
                    class="form-control" 
                    placeholder="e.g. Administrator" 
                  /> 
                  <VValidate 
                    name="Nama Level" 
                    v-model="row.bul_level_name" 
                    :rules="mrValidation.bul_level_name" 
                  />
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-table
                  :fields="menuFields"
                  :items="mrMenuRel"
                  :tbody-tr-class="selectedRowClass"
                  responsive
                  sticky-header
                >
                  <template #head(value)>
                    <b-form-checkbox
                      :checked="SelectMenu=='Y'"
                      @change="doBindCKRoot('Menu')"
                    />
                  </template>
                  <template #cell(value)="data">
                    <div @click="doBindCKSub('Menu', data.item)" class="select_div">
                      <b-form-checkbox
                        :checked="data.item.is_selected=='Y'"
                        @change="doBindCKSub('Menu', data.item)"
                        name="select-check"
                      />
                    </div>
                  </template>
                  <template #cell(label)="data">
                    <div @click="doBindCKSub('Menu', data.item)" class="select_div">
                      {{ data.value }}
                    </div>
                  </template>
                </b-table>
                <VValidate 
                  name="Menu Role" 
                  :value="((row.bul_menu_rel||[]).length||null)" :rules="mrValidation.bul_menu_rel" 
                />
              </b-col>
              <b-col v-if="mrModuleRelFilter.length">
                <b-table
                  :fields="moduleFields"
                  :items="mrModuleRelFilter"
                  :tbody-tr-class="selectedRowClass"
                  responsive
                  sticky-header
                >
                  <template #head(value)>
                    <b-form-checkbox
                      :checked="SelectModule=='Y'"
                      @change="doBindCKRoot('Module')"
                    />
                  </template>
                  <template #cell(value)="data">
                    <div @click="doBindCKSub('Module', data.item)" class="select_div">
                      <b-form-checkbox
                        :checked="data.item.is_selected=='Y'"
                        @change="doBindCKSub('Module', data.item)"
                        name="select-check"
                      />
                    </div>
                  </template>
                  <template #cell(label)="data">
                    <div @click="doBindCKSub('Module', data.item)" class="select_div">
                      {{ data.value }}
                    </div>
                  </template>
                </b-table>
              </b-col>
            </b-row>
            <div class="row mt-4">
              <div class="col-md-6">
                <!-- <div class="form-group">
                  <label class="control-label">
                    Menu Role 
                    <span class="text-danger mr5">*</span>
                  </label>
                  <VSelect multiple 
                    class="vs__multiple" 
                    v-model="row.bul_menu_rel" 
                    placeholder="Select Menu Access"
                    :options="mrMenuRel" 
                    :reduce="v=>v.value" 
                  />
                  <VValidate 
                    name="Menu Role" 
                    :value="((row.bul_menu_rel||[]).length||null)" :rules="mrValidation.bul_menu_rel" 
                  />
                </div> -->
                <div class="form-group">
                  <label class="control-label">
                    Status 
                    <span class="text-danger mr5">*</span> 
                  </label>
                  <div>
                    <b-form-radio-group
                      id="radio-group-2"
                      v-model="row[statusKey]"
                      name="radio-group-2"
                    >
                      <b-form-radio value=1>Active</b-form-radio>
                      <b-form-radio value=2>Inactive</b-form-radio>
                    </b-form-radio-group>
                  </div>
                  <VValidate 
                    name="Status" 
                    v-model="row[statusKey]" 
                    :rules="mrValidation[statusKey]" 
                  />
                </div>
              </div>
            </div>
            <div v-if="!moduleRole('ViewOnly')" class="row mt-5">
              <div class="col-md-2 offset-md-10">
                  <button type="submit" class="btn btn-block btn-rounded  btn-info">Submit</button>
              </div>
            </div>
          </form>
        </ValidationObserver>

      </div>
    </div>

  </div>
</div>
</template>

<script>
import GlobalVue from '@libs/Global'
import PageTitle from '@view/include/PageTitle'
import CardList from '@/components/CardList'
let _ = global._
export default{
  extends:GlobalVue,
  components:{PageTitle, CardList},
  data(){
    return {
      idKey:'bul_id',
      statusKey:'bul_is_active',
      fields:[
        {
          key: 'number', label: '#'
        },
        {
          key: 'bul_code', label: 'Kode Level'
        },
        {
          key:'bul_role_rel', label:'Tipe Level', is_custom
          :true
        },
        {
          key: 'bul_level_name', label: 'Nama Level'
        },
        {
          key: 'bul_is_active', label: 'Status', is_custom:true
        },
        {
          key:'aksi', is_custom:true, style:{width:'170px'}
        }
      ],
      menuFields:[
        {key: 'value', label:'', tdClass:"cursor-pointer"},
        {key: 'label', label:'Nama Menu', tdClass:"cursor-pointer"}
      ],
      moduleFields:[
        {key: 'value', label:'', tdClass:"cursor-pointer"},
        {key: 'label', label:'Nama Module', tdClass:"cursor-pointer"}
      ],
      SelectMenu:'N',
      SelectModule:'N',
      mrModuleRel:[],
      mrRole:[],
      mrMenuRel:[]
    }
  },
  computed:{
    mrModuleRelFilter(){
      return _.filter(_.clone(this.mrModuleRel), (v)=>{
        return v.role.indexOf(this.row?.bul_role_rel||"")>-1
      })
    }
  },
  methods:{
    doBindCKRoot(type){
      this['Select'+type] = this['Select'+type]=='Y'?'N':'Y'
      let data = _.map(this['mr'+type+'Rel'], (v2)=>{
        v2['is_selected'] = v2.is_auto_select=='Y'?'Y':this['Select'+type]
        return v2
      })
      this.$set(this, 'mr'+type+'Rel', data)

      this.doBindUpdate(type)
    },
    doBindCKSub(type, item){
      item.is_selected=item.is_selected=='Y'?'N':'Y'
      
      let notCheck = _.filter(this['mr'+type+'Rel'], (v)=>{return v.is_selected=='N'}).length
      if(this['Select'+type]=='Y'){
        this['Select'+type] = notCheck?'N':'Y'
      }else{
        this['Select'+type] = !notCheck?'Y':'N'
      }

      this.doBindUpdate(type)
    },
    doBindUpdate(type){
      let selectedData = _.map(_.filter(this['mr'+type+'Rel'], (v)=>{
        return v.is_selected=='Y'
      }), (v)=>{
        return v.value
      })
      
      this.$set(this.row,'bul_'+type.toLowerCase()+'_rel', selectedData)
    },
    selectedRowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.is_selected === 'Y') return 'table-success'
    },
    getModuleName(module){
      return _.map(_.filter(this.mrRole,(v)=>{
        return module.indexOf(v.value||"")>-1
      }), (v)=>{
        return v.label
      }).join(', ')||'-'
    }
  },
  mounted(){
    this.apiGet()
  },
  watch:{
    $route(){
      this.apiGet()
    },
    'row.bul_code'(v){
      this.row.bul_code = v.toUpperCase()
    },
    'row.bul_role_rel'(v,o){
      if(o&&v){
        let mrModuleRelReset = _.map(this.mrModuleRel, (v)=>{
          v.is_selected='N'
          return v
        })
        this.$set(this,'mrModuleRel',mrModuleRelReset)
      }
    }
  }
}
</script>

<style scoped>
.select_div{
  margin:-10px 0;
  padding:10px 0;
}
</style>